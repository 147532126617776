import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

import {Link, useHistory, useLocation} from "react-router-dom";
import CustomToolbarSelect from "./toolbar-select";
import ApiService from "../../service";
import {Snackbar, Switch} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import SnackBarComponent from "../shared/snack-bar";
import {format} from "date-fns";

const ToDecimal = (value) => Number(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const FormatDate = (value) => value ? format(value, 'DD/MM/YYYY'):null;

export const BooleanColumn = (value) => (<Switch checked={value} color={'primary'}/>);
export const CurrencyColumn = (value) => (<span>R$ {ToDecimal(value)}</span>);
export const NumberColumn = (value) => ToDecimal(value);
export const DateColumn = (value) => FormatDate(value);
export const OptionsColumn = (value, options) => (options.filter(op=>op.value === value)[0]?.title);

const TableComponent = ({
    title, data, columns, canDelete=true, enableEditAction=true, enableCreateAction=true, customAction
}) => {
    const classes = useStyles();
    const [dataTable, setDataTable] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const rowsSelected = useState([]);
    const location = useLocation();
    let history = useHistory();
    let path = history?.location?.pathname.slice(6);
    customAction = customAction??{"action": null, "icon": null,"label": null};

    const deleteItemAction = async (listIds) => {
        for (let index in listIds)
            await ApiService.delete(listIds[index], path);
        let response = await ApiService.get(path);
        setDataTable(response?.data);
        setOpenSnackBar(true);
    }

    let optionsDefalut = {
        ...optionsLanguage,
        rowsSelected,
        selectableRowsOnClick: true,
        customToolbarSelect: (selectedRows) =>
            <CustomToolbarSelect
                canDelete={canDelete}
                selectedRows={selectedRows}
                data={dataTable}
                deleteItemAction={deleteItemAction}
                enableEditAction={enableEditAction}
                enableCustomAction={customAction.action}
                customActionIcon={customAction.icon}
                customActionLabel={customAction.label}
            />
    }

    useEffect(() => setDataTable(data), [data])

    return (
        <div className={classes.root}>
            <AppBar position="relative">
                <Toolbar className={classes.appBar}>
                    {enableCreateAction && (<IconButton color="inherit" component={Link} to={location.pathname.replace('list', 'form')}>
                        <AddIcon />
                    </IconButton>)}
                </Toolbar>
            </AppBar>
            <MUIDataTable
                title={title}
                data={dataTable}
                columns={columns}
                options={optionsDefalut}
            />

            <SnackBarComponent open={openSnackBar}/>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    appBar: {
        display: 'flex',
        justifyContent: 'flex-end'
    }

}));


const optionsLanguage = {
    filterType: 'checkbox',
    print: false,
    download: false,
    textLabels: {
        body: {
            noMatch: "Desculpe, nenhum registro correspondente encontrado",
            toolTip: "Ordenar",
            columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
            next: "Próxima página",
            previous: "Página anterior",
            rowsPerPage: "Linhas por página:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Download CSV",
            print: "Imprimir",
            viewColumns: "Colunas",
            filterTable: "Filtros",
        },
        filter: {
            all: "Tudo",
            title: "FILTROS",
            reset: "RESET",
        },
        viewColumns: {
            title: "Mostrar Colunas",
            titleAria: "Mostrar/Esconder Columna",
        },
        selectedRows: {
            text: "Linha(s) selecionada",
            delete: "Deletar",
            deleteAria: "Deletar linhas selecionadas",
        },
    }
};

export default TableComponent
