import React, {useCallback, useEffect, useState} from 'react';
import {Box, Card, CardContent, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import validators from "../../../components/form-builder/validators";
import {FORM_BUTTON_TYPES} from "../../../components/form-builder/buttons";
import FormBuilderComponent from "../../../components/form-builder";
import {nitrogenOptions, organicFertilizerOptions, phosphateOptions, potashOptions} from "./options";
import ApiService from "../../../service";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function NpkRecommendation({id, npk, soil_analysis}) {
    const [state, setstate] = useState();
    const [tabValue, setTabValue] = useState(0);

    const fertilizers = {
        id: 'fertilizers',
        name: 'fertilizers',
        action: 'save-home',
        onChange: data=>console.log(data),
        fields: [
            {
                name: 'nitrogen',
                label: 'Nitrogenados',
                type: FORM_FIELD_TYPES.SELECT,
                options: nitrogenOptions,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'phosphate',
                label: 'Fosfatados',
                type: FORM_FIELD_TYPES.SELECT,
                options: phosphateOptions,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'potash',
                label: 'Potássicos',
                type: FORM_FIELD_TYPES.SELECT,
                options: potashOptions,
                validators: {
                    required: validators.required(),
                },
            },
        ],
        buttons: [{
            name: 'submit',
            label: 'Enviar',
            type: FORM_BUTTON_TYPES.SUBMIT,
        }]
    }

    const organic = {
        id: 'organic',
        name: 'organic',
        action: 'save-home',
        onChange: data=>console.log(data),
        fields: [
            {
                name: 'organic_fertilizer_type',
                label: 'Tipo de fertilizante orgânico',
                type: FORM_FIELD_TYPES.SELECT,
                options: organicFertilizerOptions,
                validators: {
                    required: validators.required(),
                },
                fullWidth: true
            },
        ],
        buttons: [{
            name: 'submit',
            label: 'Enviar',
            type: FORM_BUTTON_TYPES.SUBMIT,
        }]
    }

    const onSubmit = useCallback(async (data, url) => {
        const response = data?.organic_fertilizer_type ? await ApiService.post(data, url) :
            await ApiService.post(data, url);

        if (response?.status !== 200) return;
        setstate(response.data);
    }, [])

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2} alignItems={'stretch'}>
                    <Grid item xs={12} md={5}>
                        <Tabs value={tabValue} onChange={(event, newValue)=>setTabValue(newValue)}>
                            <Tab label="Fertilizantes mineriais" {...a11yProps(0)} />
                            <Tab label="Adubação Orgânica" {...a11yProps(1)} />
                        </Tabs>

                        {tabValue === 0 ? <FormBuilderComponent
                            onSubmit={(data)=>{
                                data.recommendationId = id;
                                onSubmit(data, 'mineral-recommendation/');
                            }}
                            form={fertilizers}
                            elevation={0}
                        /> : <FormBuilderComponent
                            onSubmit={(data)=>{
                                data.recommendationId = id;
                                onSubmit(data, 'organic-recommendation/');
                            }}
                            form={organic}
                            elevation={0}
                        />}
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Card style={{height: '100%'}} elevation={0}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Box mb={2}>
                                            <Typography variant={'h5'}>Recomendação NPK</Typography>
                                        </Box>
                                        <Typography>Calcário: {npk?.liming?.limestone_type} {npk?.liming?.total_lime_to_apply_tn_hac} T/ha</Typography>
                                        <Typography>NPK
                                            ({npk?.npk?.formule.join('-')}): {npk?.npk?.total_tn_hac} Kg</Typography>
                                    </Grid>
                                    {state?.mineral_fertilizer && tabValue === 0 ? <Grid item xs={6}>
                                            <Box mb={2}>
                                                <Typography variant={'h5'}>Fertilizantes mineriais</Typography>
                                            </Box>
                                            <Typography>{nitrogenOptions.find(op => op.value === Object.keys(state?.mineral_fertilizer?.n)[0]).title}: {Object.values(state?.mineral_fertilizer?.n)[0]} kg</Typography>
                                            <Typography>{phosphateOptions.find(op => op.value === Object.keys(state?.mineral_fertilizer?.p)[0]).title}: {Object.values(state?.mineral_fertilizer?.p)[0]} kg</Typography>
                                            <Typography>{potashOptions.find(op => op.value === Object.keys(state?.mineral_fertilizer?.k)[0]).title}: {Object.values(state?.mineral_fertilizer?.k)[0]} kg</Typography>
                                        </Grid>
                                        : state?.organic_fertilizer && tabValue === 1  &&
                                        <Grid item xs={6}>
                                            <Box mb={2}>
                                                <Typography variant={'h5'}>Adubação orgânica</Typography>
                                            </Box>
                                            {state?.organic_fertilizer?.total_to_apply > 1000 ?
                                            <Typography>Quantidade a ser aplicada: {(state?.organic_fertilizer?.total_to_apply / 1000).toFixed(2)} t/ha</Typography> :
                                            <Typography>Quantidade a ser aplicada: {state?.organic_fertilizer?.total_to_apply} kg/ha</Typography>}
                                            {/*<Typography>QD(N): {state?.organic_fertilizer?.qd_n} kg</Typography>*/}
                                            {/*<Typography>QD(P): {state?.organic_fertilizer?.qd_p} kg</Typography>*/}
                                            {/*<Typography>QD(K): {state?.organic_fertilizer?.qd_k} kg</Typography>*/}
                                        </Grid>}
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
