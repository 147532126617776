import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent from "../../../components/table";
import ApiService from "../../../service";
import PieChartIcon from "@material-ui/icons/PieChart";
import {useHistory} from "react-router-dom";
import {Seeds} from "../../agricultural-year/form/options";

export default function SoilAnalysisListPage () {
    const [data, setData] = useState([]);
    let history = useHistory();
    const columns = [
        {name: 'grid', label: 'Grid / Talhão / Propriedade',
            options: {customBodyRender: (value)=> value?.identifier + ' - ' + value?.plot_name}},
        {
            name: "physical_lab_report",
            label: "Análise Física",
            options: {customBodyRender: (value)=>(<span>{value?.name}</span>)}
        },
        {
            name: "biological_lab_report",
            label: "Análise Biológica",
            options: {customBodyRender: (value)=>(<span>{value?.name}</span>)}
        },
        {
            name: "chemical_lab_report",
            label: "Análise Química",
            options: {customBodyRender: (value)=>(<span>{value?.name}</span>)}
        },
        {
            name: "agricultural_year",
            label: "Ano Agrícola",
            options: {customBodyRender: (value)=>(<span>{value?.name}</span>)}
        },
        {
            name: "register_date",
            label: "Data de registro",
            options: {customBodyRender: (value)=> value?.slice(0,10)}
        }
    ];

    const customAction = {
        "action": async (listIds, values) => {
            for (let index in listIds) {
                let recommendationPath = 'recommendation';
                let itemId = listIds[index];
                let recommendationData = {
                    "soil_analysis": {
                        "id": itemId
                    },
                    "npk": "",
                    "iqs": "",
                    "soil_class": "",
                    "date": (new Date(Date.now())).toLocaleDateString()
                }
                let response = await ApiService.post(recommendationData, `${recommendationPath}/`)
                if (response?.status == 200) {
                    const idRecommendation = response.data.id;
                    history.push(`/report/${recommendationPath}`+`?id=${idRecommendation}`);
                }
            }
        },
        "icon": (<PieChartIcon/>),
        "label": "Gerar Relatório"
    };

    const getData = useCallback(async () => {
        let response = await ApiService.get('soil-analysis/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);


    return <TableComponent
        columns={columns}
        data={data}
        title={'Análises de solo'}
        customAction={customAction}
    />
}
