import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export default function SnackBarComponent({open, message="Operação realizada com sucesso!", severity="success"}) {
    const [openState, setOpen] = useState(open);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(()=>setOpen(open),[open])

    return (
        <Snackbar open={openState} autoHideDuration={1500} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}