import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent from "../../../components/table";
import ApiService from "../../../service";

export default function GridListPage (){
    const [data, setData] = useState([]);
    const columns = [
        {
            name: "identifier",
            label: "Identificador",
        },
        {
            name: "plot_name",
            label: "Talhão / Propriedade",
        },
        {
            name: "lat",
            label: "Lat",
        },
        {
            name: "lng",
            label: "Lng",
        },
        {
            name: "area_hectare",
            label: "Área(ha)",
        }
    ];

    const getData = useCallback(async () => {
        let response = await ApiService.get('grid/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);


    return <TableComponent
        columns={columns}
        data={data}
        title={'Grids'}
    />
}
