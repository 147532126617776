export const PlantingType = [
    {title: 'Safra 20/21', value: 'harvest_20_21'},
    {title: 'Safrinha 20/21', value: 'off-season_20_21'},
    {title: 'Safra 21/22', value: 'harvest_21_22'},
    {title: 'Safrinha 21/22', value: 'off-season_21_22'},
    {title: 'Safra 22/23', value: 'harvest_22_23'},
    {title: 'Safrinha 22/23', value: 'off-season_22_23'},
    {title: 'Safra 24/25', value: 'harvest_24_25'},
    {title: 'Safrinha 24/25', value: 'off-season_24_25'},

];

export const Seeds = [
    {title: 'Milho', value: 'corn'},
]
