import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Card, CardContent, CardHeader,
    Collapse, Grid,
    IconButton, LinearProgress, Typography
} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';
import {
    Alert,
    AlertTitle
} from '@material-ui/lab';

import FormFieldComponent, {FORM_FIELD_TYPES} from './fields';
import FormButtonComponent from './buttons';
import {useForm} from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import ApiService from "../../service";
import SnackBarComponent from "../shared/snack-bar";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * Receive a form json object and generate the form component.
 * 
 * Ex: {name, autoComplete, fields, buttons}
 * 
 * @param json field 
 * @returns Renderable field component.
 */
export default function FormBuilderComponent({form, title, onSubmit, elevation=2}) {
    const [render, setRender] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const { control, register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
    const [severitySnackBar, setSeveritySnackBar] = useState('success');
    const [takeValues, setTakeValues] = useState({});


    let history = useHistory();
    let query = useQuery();
    const path = history?.location?.pathname.slice(6);
    const objectId = query.get('id');

    const getDataToPut = useCallback(async (id) => {
        const response = await ApiService.get(`${path}/${id}/`);
        if (response.status !== 200){
            setRender(true);
            return;
        }

        for (let [key, value] of Object.entries(response.data)){
            setValue(key, value);
        }
        setRender(true);

    },[])

    const handleSubmitSuccess = (result) => {
        if (result?.status !== 200 && result?.status !== 201) return handleSubmitError(result);
        history.push(history.location.pathname.replace('form', 'list'));
    }
    
    const handleSubmitError = (error) => {
        setSeveritySnackBar('error');
        setOpenSnackBar(true);
    }

    const submitForm = async (data) => {
        form.fields.map(field => {
            if (!field?.defaultValue) return;
            if (!data[field.name] && field.type === FORM_FIELD_TYPES.DATE) data[field.name] = field.defaultValue.slice(0,10).split('/').reverse().join('/');
            if (field.type === FORM_FIELD_TYPES.SELECT) data[field.name] = takeValues[field.name];
        })
        data = {...data, ...takeValues};
        if (onSubmit){
            onSubmit(data);
            return
        }
        let response;
        if (objectId)
            response = await ApiService.put(data, `${path}/${objectId}/`);
        else
            response = await ApiService.post(data, `${path}/`);

        handleSubmitSuccess(response);
    }

    const renderFields = () => {
        var fields = [];
        for (let field of form.fields) {
            fields.push(<FormFieldComponent
                key={field?.name}
                setValue={setValue}
                watch={watch}
                field={field}
                register={register}
                errors={errors}
                control={control}
                setTakeValues={setTakeValues}
                takeValues={takeValues}
            />);
        }
        return (fields);
    }

    const renderButtons = () => {
        var buttons = [];
        for (var formButtons of form.buttons) {
            buttons.push(<FormButtonComponent key={formButtons?.name} button={formButtons} />);
        }
        return (buttons);
    }

    useEffect(()=>{
        // if (objectId && history?.location?.pathname.slice(5) === 'form')
        if (objectId)
            getDataToPut(objectId);
        else
            setRender(true);

        return reset()
    },[form]);
    return (
        <Box width={1} display={'flex'} justifyContent={'center'}>
            <Box width={1} maxWidth={1000}>
                {!render && <LinearProgress />}
                <Card elevation={elevation}>
                    <CardContent>
                        <Box mb={2}>
                            <Typography component="h1" variant="h5">
                                {title}
                            </Typography>
                        </Box>
                        {render && <form onSubmit={handleSubmit(submitForm)}
                               autoComplete={form.autoComplete ? 'on' : 'off'}
                               id={form.id}
                               name={form.name}>
                            <Grid container spacing={2}>
                                {renderFields()}
                            </Grid>
                            <Box mt={2} display={'flex'} justifyContent={'flex-end'} flexWrap={'wrap'}>{renderButtons()}</Box>
                        </form>}
                    </CardContent>
                    <SnackBarComponent open={openSnackBar} severity={severitySnackBar}/>
                </Card>
            </Box>
        </Box>

    );
}
