import * as React from 'react';
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import {DEFAULT_BUTTON_SET} from "../../../components/form-builder/buttons";
import FormBuilderComponent from "../../../components/form-builder";
import validators from "../../../components/form-builder/validators";

export default function GridFormPage() {
    const formMock = {
        id: 'form',
        name: 'form',
        action: 'save-home',
        fields: [
            {
                name: 'identifier',
                label: 'Identiicador',
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                name: 'plot',
                label: 'Talhão',
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'plot/search-by-name',
                optionKey: 'name'
            },
            {
                name: 'lat',
                label: 'Lat',
                type: FORM_FIELD_TYPES.NUMBER,
                decimalPrecision: '0.000001'
            },
            {
                name: 'lng',
                label: 'Lng',
                type: FORM_FIELD_TYPES.NUMBER,
                decimalPrecision: '0.000001'
            },
            {
                name: 'area_hectare',
                label: 'Área(ha)',
                type: FORM_FIELD_TYPES.NUMBER,
                defaultValue: 0
            },
        ],
        buttons: DEFAULT_BUTTON_SET
    }

    return <FormBuilderComponent form={formMock} title={'Cadastro de Grid'} />
}
