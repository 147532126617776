import * as React from 'react';
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import {DEFAULT_BUTTON_SET} from "../../../components/form-builder/buttons";
import FormBuilderComponent from "../../../components/form-builder";
import validators from "../../../components/form-builder/validators";
import {
    AreaCondition,
    CurrentCulture,
    FirstSecondPlanting,
    ManagementSystem,
    predecessor_culture,
    PredecessorCulture
} from "./options";

export default function PlotFormPage() {
    const formMock = {
        id: 'plot',
        name: 'plot',
        action: 'save-home',
        fields: [
            {
                name: 'agricultural_year',
                label: 'Ano Agrícola',
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'agricultural-year/search-by-user',
                optionKey: 'name'
            },
            {
                name: 'farm',
                label: 'Propriedade',
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'farm/search-by-name',
                optionKey: 'name'
            },
            {
                name: 'identifier',
                label: 'Identificador',
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                name: 'area_hectare',
                label: 'Área(ha)',
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'plant_density_hectare',
                label: 'Densidade de plantas(ha)',
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'yield_expectation_kg',
                label: 'Expectativa de rendimento(kg/ha)',
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'prnt_applied',
                label: 'Granularidade PRNT (%)',
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    min: validators.min(0),
                    max: validators.max(100),
                }
            },
            {
                name: 'first_or_second_planting',
                label: 'Plantio',
                type: FORM_FIELD_TYPES.SELECT,
                options: FirstSecondPlanting
            },
            {
                name: 'predecessor_culture',
                label: 'Cultura Antecessora',
                type: FORM_FIELD_TYPES.SELECT,
                options: PredecessorCulture
            },
            {
                name: 'current_culture',
                label: 'Cultura',
                type: FORM_FIELD_TYPES.SELECT,
                options: CurrentCulture
            },
            {
                name: 'management_system',
                label: 'Sistema de manejo',
                type: FORM_FIELD_TYPES.SELECT,
                options: ManagementSystem
            },
            {
                name: 'area_condition',
                label: 'Condição da área',
                type: FORM_FIELD_TYPES.SELECT,
                options: AreaCondition
            },
        ],
        buttons: DEFAULT_BUTTON_SET //ARRAY WITH THE BUTTONS TO ADD (SEE FormButtonComponent TO FIND DOC)
    }

    return <FormBuilderComponent form={formMock} title={'Cadastro de Talhões'}/>
}
