import { cnpj, cpf } from 'cpf-cnpj-validator';

const validators = {
    required: () => ({
        value: true,
        message: 'Campo Obrigatório.',
    }),
    min: (length) => ({
        value: length,
        message: `Digíte um número acima de ${length}.`,
    }),
    max: (length) => ({
        value: length,
        message: `Digíte um número abaixo de ${length}.`,
    }),
    minLength: (length) => ({
        value: length,
        message: `Digíte no mínimo ${length} caracteres.`,
    }),
    maxLength: (length) => ({
        value: length,
        message: `Digíte no máximo ${length} caracteres.`,
    }),
    cpf: (data) => (cpf.isValid(data) ? null : 'Digite um CPF válido.'),
    cnpj: (data) => (cnpj.isValid(data) ? null : 'Digite um CNPJ válido.'),
};

export default validators;