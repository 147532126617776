import React, {useEffect, useState} from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {ptBR} from "date-fns/locale";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    w100: {
        width: '100%'
    },
}));

export default function DatePickerField({watch, label, setValue, name, defaultValue, readOnly}) {

    const [value, setValueDatePicker] = useState(watch(name) || defaultValue);
    const classes = useStyles();

    useEffect(()=> {
        if (watch(name)){
            setValue(name, watch(name).slice(0,10).split('-').reverse().join('/'));
        }
        else{
            setValue(name, defaultValue)
        }

    }, [name])

    return (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
                disabled={readOnly}
                variant={'inline'}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                label={label || name}
                value={value}
                onChange={(date, value) => {
                    setValue(name, value)
                    setValueDatePicker(date)
                }}
                className={classes.w100}
                autoOk
            />
        </MuiPickersUtilsProvider>
    );
}
