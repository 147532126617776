import * as React from 'react';
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import {DEFAULT_BUTTON_SET} from "../../../components/form-builder/buttons";
import FormBuilderComponent, {useQuery} from "../../../components/form-builder";
import validators from "../../../components/form-builder/validators";
import {useCallback, useState} from "react";
import ApiService from "../../../service";
import {format} from "date-fns";

export default function PhysicalLabReportFormPage() {
    const [gridOptions, setGridOptions] = useState([]);

    const onChangeFarm = useCallback(async (watch, setValue) => {
        const response = await ApiService.get('grid/search-by-farm',{id: watch('farm').id})
        if (response?.status !== 200) return;
        setGridOptions(response.data);
    }, [])

    const onChange = (watch, setValue)=>{
        if(watch('farm'))
            onChangeFarm(watch, setValue);
    }

    const formMock = {
        id: 'form',
        name: 'form',
        action: 'save-home',
        fields: [
            {
                name: 'farm',
                label: 'Propriedade',
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'farm/search-by-name',
                optionKey: 'name',
                onChange
            },
            {
                name: 'grid',
                label: 'Grid',
                type: FORM_FIELD_TYPES.SELECT,
                options: gridOptions,
                optionValue: false,
                optionTitle: 'name',
            },
            {
                name: 'analysis_date',
                label: "Data análise",
                type: FORM_FIELD_TYPES.DATE,
                validators: {
                    required: validators.required(),
                },
                defaultValue: null
            },
            {
                name: 'register_date',
                label: "Data de registo",
                type: FORM_FIELD_TYPES.DATE,
                validators: {
                    required: validators.required(),
                },
                defaultValue: format(new Date(), 'yyyy/MM/dd')
            },
            {
                name: 'name_lab',
                label: "Laboratório",
                type: FORM_FIELD_TYPES.TEXT,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'report_number_lab',
                label: "número de relatório do laboratório",
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                name: 'sample_number_lab',
                label: "número da amostra do laboratório",
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                name: 'sand',
                label: "Areia",
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'clay',
                label: "Argila",
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'silt',
                label: "Lodo",
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    required: validators.required(),
                },
            },
        ],
        buttons: DEFAULT_BUTTON_SET //ARRAY WITH THE BUTTONS TO ADD (SEE FormButtonComponent TO FIND DOC)
    }

    return <FormBuilderComponent form={formMock} title={'Cadastro de Análise Física'}/>
}
