import * as React from 'react';
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import {DEFAULT_BUTTON_SET} from "../../../components/form-builder/buttons";
import FormBuilderComponent from "../../../components/form-builder";
import validators from "../../../components/form-builder/validators";
import {useForm} from "react-hook-form";
import {useCallback, useState} from "react";
import ApiService from "../../../service";
import {format} from "date-fns";

export default function ChemicalAnalysisFormPage() {
    const [gridOptions, setGridOptions] = useState([]);

    const onChangeFarm = useCallback(async (watch, setValue) => {
        const response = await ApiService.get('grid/search-by-farm',{id: watch('farm').id})
        if (response?.status !== 200) return;
        setGridOptions(response.data);
    }, [])

    const onChange = (watch, setValue)=>{
        if(watch('farm'))
            onChangeFarm(watch, setValue);
    }

    const formMock = {
        id: 'chemical-analysis',
        name: 'chemical-analysis',
        action: 'save-home',
        fields: [
            {
                name: 'farm',
                label: 'Propriedade',
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'farm/search-by-name',
                optionKey: 'name',
                onChange
            },
            {
                name: 'grid',
                label: 'Grid',
                type: FORM_FIELD_TYPES.SELECT,
                options: gridOptions,
                optionValue: 'id',
                optionTitle: 'name',
            },
            {
                name: 'name_lab',
                label: 'Nome do laboratório',
                type: FORM_FIELD_TYPES.TEXT,
            },
            {
                name: 'register_date',
                label: 'Data de registro',
                type: FORM_FIELD_TYPES.DATE,
                defaultValue: format(new Date(), 'yyyy/MM/dd')
            },
            {
                name: 'analysis_date',
                label: 'Data análise',
                type: FORM_FIELD_TYPES.DATE,
                defaultValue: null
            },
            {
                name: 'report_number_lab',
                label: "número de relatório do laboratório",
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                name: 'sample_number_lab',
                label: "número da amostra do laboratório",
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },

            // Liming
            {
                name: 'ph_water',
                label: 'Ph em água',
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'smp',
                label: 'Índice SMP',
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'relation_ca_mg',
                label: 'Relação Ca/Mg',
                type: FORM_FIELD_TYPES.NUMBER
            },

            // Nitrogen
            {
                name: 'clay',
                label: 'Argila',
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'organic_matter',
                label: 'Matéria orgânica',
                type: FORM_FIELD_TYPES.NUMBER
            },

            // Phosphor
            {
                name: 'p_mg_l',
                label: 'P mg/l',
                type: FORM_FIELD_TYPES.NUMBER
            },

            // Potassium
            {
                name: 'k_mg_l',
                label: 'K mg/l',
                type: FORM_FIELD_TYPES.NUMBER
            },
            {
                name: 'ctc_dm_3',
                label: 'CTC',
                type: FORM_FIELD_TYPES.NUMBER
            },
        ],
        buttons: DEFAULT_BUTTON_SET,
        onChange: onChange
    }

    return <FormBuilderComponent
        form={formMock}
        title={'Análise Química'}
    />
}
