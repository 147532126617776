import axios from "axios";


// const URL_BASE = process.env.REACT_APP_API_URL
const URL_BASE = `${process.env.REACT_APP_BACK_URL}`
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const fetchApi = async (data, path, method='GET', params={}) => {
    let headers = {
        'Content-Type': 'application/json'
    }

    if (!path.includes('login') && !path.includes('register'))
        headers.Authorization = `Token ${localStorage.getItem('AuthorizationToken')}`;

    let response;
    try {
        response = await axios({
            headers,
            data,
            method,
            params,
            cancelToken: source.token,
            url: `${URL_BASE + path}`
        });
        return response;

    } catch (error) {
        if (axios.isAxiosError(error)) {
            // handleAxiosError(error);
            // console.log('handleAxiosError', error.response)
            return error.response
        } else {
            // handleUnexpectedError(error);
            // console.log('handleUnexpectedError', error.toJSON())
        }
    }
}

const ApiService = {
    get: (path, params={}) => fetchApi({}, path, 'GET', params),
    post: (data, path) => fetchApi(data, path, 'POST'),
    put: (data, path) => fetchApi(data, path, 'PUT'),
    delete: (id, path) => fetchApi({}, `${path}/${id}`, 'DELETE'),
}

export default ApiService;
