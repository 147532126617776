import React, { PureComponent } from 'react';
import {Box, Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import ChemicalBar from "./chemical-bar";

const data = [
    {
        name: 'Page G',
        uv: 3490,
    },
];

export default function ChemicalRecommendation ({organic_matter_class, clay_class, potassium_class, phosphor_class, ctc}) {

    const prepClassifierValue = (value) => {
        switch (value) {
            case 'very_low':
                return 'veryLow';
            case 'very_high':
                return 'veryHigh';
            default:
                return value;
        }
    }
    const prepClassifierLabel = (value) => {
        switch (value) {
            case 'very_low':
                return 'Muito baixo';
            case 'low':
                return 'Baixo';
            case 'medium':
                return 'Médio';
            case 'high':
                return 'Alto';
            case 'very_high':
                return 'Muito alto';
            default:
                return value;
        }
    }

    return (
        <Card>
            <CardContent>
                <Typography variant={'h5'}>
                    Interpretação Química
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography>Muito baixo</Typography>
                                <ChemicalBar chipLabel={prepClassifierLabel()} indexClass={'veryLow'} legend />
                            </Grid>
                            <Grid item>
                                <Typography>Baixo</Typography>
                                <ChemicalBar chipLabel={prepClassifierLabel()} indexClass={'low'} legend />
                            </Grid>
                            <Grid item>
                                <Typography>Médio</Typography>
                                <ChemicalBar chipLabel={prepClassifierLabel()} indexClass={'medium'} legend />
                            </Grid>
                            <Grid item>
                                <Typography>Alto</Typography>
                                <ChemicalBar chipLabel={prepClassifierLabel()} indexClass={'high'} legend />
                            </Grid>
                            <Grid item>
                                <Typography>Muito alto</Typography>
                                <ChemicalBar chipLabel={prepClassifierLabel()} indexClass={'veryHigh'} legend />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography>Argila:</Typography>
                        <ChemicalBar
                            chipLabel={
                                clay_class === 1 ? 'Muito alto' :
                                    clay_class === 2 ? 'Alto' :
                                        clay_class === 3 ? 'Médio' : 'Baixo'
                            }
                             indexClass={
                                clay_class === 1 ? 'veryHigh' :
                                clay_class === 2 ? 'high' :
                                clay_class === 3 ? 'medium' : 'low'
                            } />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography>Ctc:</Typography>
                        <ChemicalBar
                            chipLabel={
                                ctc === 1 ? 'Muito alto' :
                                    ctc === 2 ? 'Alto' :
                                        ctc === 3 ? 'Médio' : 'Baixo'
                            }
                             indexClass={
                                ctc === 1 ? 'veryHigh' :
                                ctc === 2 ? 'high' :
                                ctc === 3 ? 'medium' : 'low'
                            } />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography>Potássio:</Typography>
                        <ChemicalBar chipLabel={prepClassifierLabel(potassium_class)} indexClass={prepClassifierValue(potassium_class)}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography>Matéria orgânica:</Typography>
                        <ChemicalBar chipLabel={prepClassifierLabel(organic_matter_class)} indexClass={prepClassifierValue(organic_matter_class)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography>Fósforo:</Typography>
                        <ChemicalBar chipLabel={prepClassifierLabel(phosphor_class)} indexClass={prepClassifierValue(phosphor_class)} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        // <ResponsiveContainer width="100%" height="100%">

        // </ResponsiveContainer>
    );

}
