import { createTheme } from '@material-ui/core/styles';
import {green, red} from "@material-ui/core/colors";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#4CAf50', //'#00b1ee'
        },
        secondary: {
            main: '#73B742',
        },
        tertiary: {
            main: '#DD291A',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#FFF',
        },
        color: {
            default: '#FFF',
        },
    },
});