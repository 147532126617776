import React, {useCallback, useState, useEffect} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ChemicalRecommendation from "./chemical-recommendation";
import NpkRecommendation from "./npk-recommendation/npk-recommendation";
import IqsRecommendation from "./soil-recommendation/iqs-recommendation";
import SoilClassDefinition from "./soil-recommendation/soil-class-definition";
import ApiService from "../../service/index";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function RecommendationPage() {
    const [dataToShow, setDataToShow] = useState({"npk": {}, "iqs":{}, "soil_class":{}});

    let history = useHistory();
    let query = useQuery();
    const path = history?.location?.pathname.slice(8);
    const objectId = query.get('id');

    const getDataToShow = useCallback(async (id) => {
        const response = await ApiService.get(`${path}/${id}/`);
        if (response?.status === 200)
            setDataToShow(response.data);
    },[])

    useEffect(()=>{
        if (objectId)
            getDataToShow(objectId);
    }, [objectId]);

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <NpkRecommendation {...dataToShow}/>
            {/*<NpkRecommendation data={dataToShow.npk} {...dataToShow.npk} />*/}
        </Grid>
        <Grid item xs={12}>
            <ChemicalRecommendation {...dataToShow.npk}/>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*    <IqsRecommendation data={dataToShow.iqs}/>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*    <SoilClassDefinition data={dataToShow.soil_class}/>*/}
        {/*</Grid>*/}
    </Grid>
}
