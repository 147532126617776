import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent, {OptionsColumn} from "../../../components/table";
import ApiService from "../../../service";
import {PlantingType, Seeds} from "../form/options";

export default function AgriculturalYearListPage (){
    const [data, setData] = useState([]);
    const columns = [
        // {
        //     name: "plot",
        //     label: "Talhão",
        //     options: {customBodyRender: (value)=>(<span>{value?.identifier}</span>)}
        // },
        {
            name: "planting_type",
            label: "Tipo de plantio",
            options: {customBodyRender: (value)=>OptionsColumn(value, PlantingType)},
        },
        {
            name: "seed",
            label: "Semente",
            options: {customBodyRender: (value)=>OptionsColumn(value, Seeds)},
        },
        {
            name: "harvested_bags",
            label: "Sacas colhidas",
        },
        {
            name: "planting_date",
            label: "Data de plantio",
            options: {customBodyRender: (value)=> value?.slice(0,10)}
        },
        {
            name: "hasvest_date",
            label: "Data de colheita",
            options: {customBodyRender: (value)=> value?.slice(0,10)}
        }
    ];

    const getData = useCallback(async () => {
        let response = await ApiService.get('agricultural-year/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);


    return <TableComponent
        columns={columns}
        data={data}
        title={'Anos Agrícolas'}
    />
}
