import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Chip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    w24: {width: '24px !important' },
    veryLow: {width: '10%', background: '#ff0023'},
    low: {width: '30%', background: '#ff6e3b'},
    medium: {width: '50%', background: '#fffc74'},
    high: {width: '70%', background: '#9afd71'},
    veryHigh: {width: '100%', background: '#33ae67'},
}));

export default function ChemicalBar({indexClass, legend=false, chipLabel}) {
    const classes = useStyles();
    let classConfigs = [classes[indexClass]]

    if (legend)
        classConfigs.push(classes.w24)

    return (
        <Box width={1}>
            <Chip
                size={'small'}
                className={classConfigs}
                label={chipLabel}
            />
        </Box>
    );
}
