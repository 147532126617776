import React from 'react';
import {
    Grid,
    TextField,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {makeStyles} from "@material-ui/core/styles";
import AutoCompleteField from "./autocomplete";
import SelectField from "./select";
import DatePickerField from "./datepicker";
import renderNumberFormatField from "./number-format";
import RenderNumberFormatField from "./number-format";

export const FORM_FIELD_TYPES = {
    SELECT: 'select',
    TEXT: 'text',
    AUTOCOMPLETE: 'autocomplete',
    PASSWORD: 'password',
    DATE: 'date',
    NUMBER: 'number',
    EMAIL: 'email'
}

const useStyles = makeStyles((theme) => ({
    w100: {
        width: '100%'
    },
}));

/**
 * Receive a field json object and generate the field component.
 * 
 * Ex: {id, name, label, description, defaultValue, required, error, type}
 * 
 * @param json field 
 * @returns Renderable field component.
 */
export default function FormFieldComponent({field, register, errors, setValue, watch, control, takeValues, setTakeValues}) {
    const classes = useStyles();

    const renderFields = () => {

        if(field === undefined || field.length < 1) return (<Alert severity="info">Não há campos configurados para esse formulário.</Alert>);
        if (field.hasOwnProperty('onChange'))
            field?.onChange(watch, setValue);

        switch (field.type) {
            case FORM_FIELD_TYPES.TEXT:
            case FORM_FIELD_TYPES.EMAIL:
            case FORM_FIELD_TYPES.NUMBER:
                return renderTextField();
            // case FORM_FIELD_TYPES.NUMBER:
            //     return renderNumberField();
            case FORM_FIELD_TYPES.DATE:
                return renderDateField();
            case FORM_FIELD_TYPES.AUTOCOMPLETE:
                return autoCompleteField();
            case FORM_FIELD_TYPES.SELECT:
                return selectField();
            default: return (<Alert severity="error">Erro ao renderizar campo do formulário!</Alert>);
        }
    }

    const renderTextField = () => {
        return (
            <Grid item xs={12} sm={4} lg={3}>
                <TextField
                    {...register(field.name, field?.validators)}
                    inputProps={{
                        step: field?.decimalPrecision?field?.decimalPrecision:"0.01"
                    }}
                    id={field.id}
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    defaultValue={field?.defaultValue}
                    error={!!errors[field?.name]}
                    helperText={errors[field.name]?.message}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
        );
    }

    const renderNumberField = () => {
        return (
            <Grid item xs={12} sm={4} lg={3}>
                <RenderNumberFormatField {...field} setValue={setValue} watch={watch} register={register} />
            </Grid>
        );
    }

    const renderDateField = () => {
        return (
            <Grid item xs={12} sm={4} lg={3}>
                <DatePickerField {...field} watch={watch} setValue={setValue} control={control} />
            </Grid>
        );
    }

    const autoCompleteField = () => {
        return (
            <Grid item xs={12} sm={4} lg={3}>
                <AutoCompleteField
                    setValue={setValue}
                    watch={watch}
                    control={control}
                    register={register}
                    errors={errors}
                    takeValues={takeValues}
                    setTakeValues={setTakeValues}
                    {...field}/>
            </Grid>
        );
    }

    const selectField = () => {
        let sm = field?.fullWidth ? 12 : 4;
        let lg = field?.fullWidth ? 12 : 3;
        return (
            <Grid item xs={12} sm={sm} lg={lg}>
                <SelectField watch={watch} {...field} setValue={setValue} control={control} errors={errors}
                             takeValues={takeValues}
                             setTakeValues={setTakeValues}/>
            </Grid>
        )
    }

    return (renderFields());
}
