import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Box} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Controller} from "react-hook-form";

export default function SelectField(
    {
        watch, label, options, setValue, name, errors, optionValue='value', takeValues, setTakeValues,
        optionTitle='title', defaultValue=true, validators, control
    }) {
    const [value, setValueSelect] = useState(watch(name) || defaultValue);

    useEffect(()=>{
        const val = options.filter(option => option[optionValue] === watch(name));
        if (val.length > 0)
            setValueSelect(val[0]);

    },[])


    // console.log(watch())
    //
    // if (name === 'grid'){
    //     console.log(value)
    //     console.log(value && !watch(name) && value !== value)
    //     console.log(value !== value, watch(name) !== value)
    //     console.log(value, !watch(name), value !== value)
    //     console.log('||||||||||||')
    //     if (value && !watch(name) && value !== watch(name) && value !== value)
    //         setValue(name, value);
    //
    // }

    return (
        <Box>
            {value && <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={validators}
                render={({ field }) => <Autocomplete
                    value={value}
                    defaultValue={defaultValue}
                    onChange={(e, v) => {
                        // if (!v) v.value = {};
                        let value = optionValue && v?.hasOwnProperty(optionValue) ? v[optionValue] : v;
                        setValue(name, value);
                        setValueSelect(v || []);
                        setTakeValues({...takeValues, [name]: value})
                    }}
                    id={name}
                    options={options}
                    getOptionLabel={(option) => String(option[optionTitle] || '')}
                    fullWidth
                    renderInput={(params) => <TextField
                        {...params}
                        label={label}
                        error={!!errors[name]}
                        helperText={errors[name]?.message}
                        variant="outlined"
                    />}
                />}
            />}
        </Box>
    );
}
