import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link, useHistory, useLocation} from "react-router-dom";
import ApiService from "../../service";
import {useForm} from "react-hook-form";
import SnackBarComponent from "../../components/shared/snack-bar";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginPage({useAuth}) {
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const classes = useStyles();
    let history = useHistory();
    let auth = useAuth();

    const onSubmit = async (data) => {
        let response = await ApiService.post(data, 'login/');
        if (response?.status !== 200) {

            return
        }
        localStorage.setItem('AuthorizationToken', response.data.token);

        auth.signin(() => {
            history.replace('/home');
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <SnackBarComponent severity={'error'} message={snackBarMessage} open={snackBarOpen}  />
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                {...register('username', {required: true, minLength: 3})}
                                name="username"
                                variant="outlined"
                                fullWidth
                                id="username"
                                label="Usuário"
                                error={!!errors['username']}
                                helperText={errors['username']?.message}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                {...register('password', {required: true, minLength: 3})}
                                variant="outlined"
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={!!errors['password']}
                                helperText={errors['password']?.message}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        type={'submit'}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Entrar
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link to={'register'} variant="body2">
                                {"Não tem uma conta? Inscrever-se"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
