import * as React from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import AppNavigation from "./navigation";

import {theme} from "./theme";

function App() {
  return (
      <ThemeProvider theme={theme}>
        <AppNavigation />
      </ThemeProvider>
  );
}

export default App;