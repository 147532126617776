import React, { useContext, createContext, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import LoginPage from "./pages/login";
import {router} from "./router";
import TemplateComponent from "./components/template";
import RegisterPage from "./pages/register";

export default function AppNavigation() {
    return (
        <ProvideAuth>
            <Router>
                <div>
                    <Switch>
                        <Route path="/" exact>
                            <LoginPage useAuth={useAuth} />
                        </Route>
                        <Route path="/login">
                            <LoginPage useAuth={useAuth} />
                        </Route>
                        <Route path="/register">
                            <RegisterPage useAuth={useAuth} />
                        </Route>
                        {router.map((item, index) => {
                            if (item.path === '/')
                                return (
                                    <PrivateRoute exact key={index} path={item.path}>
                                        <TemplateComponent useAuth={useAuth} content={item.component} />
                                    </PrivateRoute>
                                )
                            else
                                return (
                                    <PrivateRoute key={index} path={item.path}>
                                        <TemplateComponent useAuth={useAuth} content={item.component} />
                                    </PrivateRoute>
                                )
                        })}
                    </Switch>
                </div>
            </Router>
        </ProvideAuth>
    );
}

const auth = {
    isAuthenticated: !!localStorage.getItem('AuthorizationToken'),
    signin(cb) {
        auth.isAuthenticated = true;
        cb();
    },
    signout(cb) {
        auth.isAuthenticated = false;
        cb();
    }
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null);

    const signin = cb => {
        return auth.signin((username='user') => {
            setUser(username);
            cb();
        });
    };

    const signout = cb => {
        return auth.signout(() => {
            setUser(null);
            cb();
        });
    };

    return {
        user,
        signin,
        signout
    };
}

function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                localStorage.getItem('AuthorizationToken') ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}