import React from 'react';
import {Box, Card, CardContent, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    graphImage: {
        width: 300,
        height: 250,
    },
}));

export default function SoilClassDefinition({data}) {
    const classes = useStyles();

    return (
        <Card style={{height: '100%'}}>
            <CardContent>
                <Typography variant={'h5'}>
                    Classe Textural do Solo
                </Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid item xs={6}>
                            <Typography>Argila: {data?.clay}%</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Silte: {data?.silt}%</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Areia: {data?.sand}%</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Nome Geral: {data?.general_name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Textura: {data?.texture}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>Classe Textural: {data?.texture_class}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img className={classes.graphImage} src={"/images/TEXTURE_TRIANGLE_"+((data?.texture_id)?data.texture_id:"NONE")+".png"} alt={data?.texture_class} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
