import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent from "../../../components/table";
import ApiService from "../../../service";

export default function FarmListPage (){
    const [data, setData] = useState([]);

    const getData = useCallback(async () => {
        let response = await ApiService.get('farm/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);


    return <TableComponent
        columns={[
            {
                name: "name",
                label: "Nome",
            },
            {
                name: "owner",
                label: "Proprietário",
            },
            {
                name: "city",
                label: "Cidade",
            }
        ]}
        data={data}
        title={'Propriedades'}
    />
}
