import * as React from 'react';
import {format} from "date-fns";
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import {DEFAULT_BUTTON_SET} from "../../../components/form-builder/buttons";
import FormBuilderComponent from "../../../components/form-builder";
import validators from "../../../components/form-builder/validators";
import {useCallback, useState} from "react";
import ApiService from "../../../service";

export default function BiologicalLabReportFormPage() {
    const [gridOptions, setGridOptions] = useState([]);

    const onChangeFarm = useCallback(async (watch, setValue) => {
        const response = await ApiService.get('grid/search-by-farm',{id: watch('farm').id})
        if (response?.status !== 200) return;
        setGridOptions(response.data);
    }, [])

    const onChange = (watch, setValue)=>{
        if(watch('farm'))
            onChangeFarm(watch, setValue);
    }

    const formMock = {
        id: 'form',
        name: 'form',
        action: 'save-home',
        fields: [
            {
                name: 'farm',
                label: 'Propriedade',
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'farm/search-by-name',
                optionKey: 'name',
                onChange
            },
            {
                name: 'grid',
                label: 'Grid',
                type: FORM_FIELD_TYPES.SELECT,
                options: gridOptions,
                optionValue: false,
                optionTitle: 'name',
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'analysis_date',
                label: "Data análise",
                type: FORM_FIELD_TYPES.DATE,
                validators: {
                    required: validators.required(),
                },
                defaultValue: null
            },
            {
                name: 'register_date',
                label: "Data de registo",
                type: FORM_FIELD_TYPES.DATE,
                validators: {
                    required: validators.required(),
                },
                defaultValue: format(new Date(), 'yyyy/MM/dd')
            },
            {
                name: 'name_lab',
                label: "Nome Laboratório",
                type: FORM_FIELD_TYPES.TEXT,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'report_number_lab',
                label: "número de relatório do laboratório",
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                name: 'sample_number_lab',
                label: "número da amostra do laboratório",
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                name: 'cbm',
                label: "Cbm",
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'beta_glucosidase',
                label: "Beta Glucosidase",
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'ariphosphatase',
                label: "Arifosfatase",
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'acid_phosphatase',
                label: "Fosfatase Ácida",
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    required: validators.required(),
                },
            },
            {
                name: 'organic_matter',
                label: "Matéria Orgânica",
                type: FORM_FIELD_TYPES.NUMBER,
                validators: {
                    required: validators.required(),
                },
            },
        ],
        buttons: DEFAULT_BUTTON_SET
    }

    return <FormBuilderComponent form={formMock} title={'Cadastro de Análise Biológica'}/>
}
