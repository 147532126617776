import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent, {DateColumn} from "../../../components/table";
import ApiService from "../../../service";

export default function PhysicalLabReportListPage (){
    const [data, setData] = useState([]);
    const columns = [
        {
            name: "grid",
            label: "Grid / Talhão / Propriedade",
            options: {customBodyRender: (value)=> value?.identifier + ' - ' + value?.plot_name}
        },
        {
            name: "name_lab",
            label: "Laboratório",
        },
        {
            name: "report_number_lab",
            label: "número de relatório do laboratório",
        },
        {
            name: "sand",
            label: "Areia",
        },
        {
            name: "clay",
            label: "Argila",
        },
        {
            name: "silt",
            label: "Lodo",
        }
    ];

    const getData = useCallback(async () => {
        let response = await ApiService.get('physical-lab-report/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);


    return <TableComponent
        columns={columns}
        data={data}
        title={'Análises Físicas'}
    />
}
