import FarmFormPage from "./pages/farm/form";
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FarmListPage from "./pages/farm/list";
import HomePage from "./pages/home";
import ChemicalAnalysisFormPage from "./pages/chemical-analysis/form";
import ChemicalAnalysisListPage from "./pages/chemical-analysis/list";
import PlotFormPage from "./pages/plot/form";
import PlotListPage from "./pages/plot/list";
import GridOnIcon from '@material-ui/icons/GridOn';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AgriculturalYearFormPage from "./pages/agricultural-year/form";
import DateRangeIcon from '@material-ui/icons/DateRange';
import AgriculturalYearListPage from "./pages/agricultural-year/list";
import GridFormPage from "./pages/grid/form";
import GridListPage from "./pages/grid/list";
import PhysicalLabReportFormPage from "./pages/physical-lab-report/form";
import PhysicalLabReportListPage from "./pages/physical-lab-report/list";
import BiologicalLabReportFormPage from "./pages/biological-lab-report/form";
import BiologicalLabReportListPage from "./pages/biological-lab-report/list";
import BallotIcon from '@material-ui/icons/Ballot';
import SoilAnalysisFormPage from "./pages/soil-analysis/form";
import SoilAnalysisListPage from "./pages/soil-analysis/list";
import RecommendationListPage from "./pages/recommendation/list";
import TimelineIcon from '@material-ui/icons/Timeline';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import RecommendationPage from "./pages/recommendation";

export const router = [
    {
        path: '/list/home',
        text: 'Template Component',
        icon: <DashboardIcon />,
        component: <h1>Template Component</h1>
    },
    {
        path: '/home',
        text: 'Template Component',
        icon: <DashboardIcon />,
        component: <h1>Template Component</h1>
    },

    {
        path: '/form/farm',
        component: <FarmFormPage />
    },
    {
        path: '/list/farm',
        text: 'Propriedade',
        icon: <HomeIcon />,
        component: <FarmListPage />
    },

    {
        path: '/form/agricultural-year',
        component: <AgriculturalYearFormPage />
    },
    {
        path: '/list/agricultural-year',
        text: 'Ano Agrícola',
        icon: <DateRangeIcon />,
        component: <AgriculturalYearListPage />
    },
    {
        path: '/form/plot',
        component: <PlotFormPage />
    },

    {
        path: '/list/plot',
        text: 'Talhão',
        icon: <ImageAspectRatioIcon />,
        component: <PlotListPage />
    },

    {
        path: '/form/grid',
        component: <GridFormPage />
    },
    {
        path: '/list/grid',
        text: 'Grid',
        icon: <GridOnIcon />,
        component: <GridListPage />
    },

    {
        path: '/form/physical-lab-report',
        component: <PhysicalLabReportFormPage />
    },
    {
        path: '/list/physical-lab-report',
        text: 'Laudo laboratório físico',
        icon: <GroupWorkIcon />,
        component: <PhysicalLabReportListPage />
    },

    {
        path: '/form/biological-lab-report',
        component: <BiologicalLabReportFormPage />
    },
    {
        path: '/list/biological-lab-report',
        text: 'Laudo laboratório biológico',
        icon: <BallotIcon />,
        component: <BiologicalLabReportListPage />
    },

    {
        path: '/form/chemical-lab-report',
        component: <ChemicalAnalysisFormPage />
    },
    {
        path: '/list/chemical-lab-report',
        text: 'Laudo laboratório químico',
        icon: <AssessmentIcon />,
        component: <ChemicalAnalysisListPage />
    },

    {
        path: '/form/soil-analysis',
        component: <SoilAnalysisFormPage />
    },
    {
        path: '/list/soil-analysis',
        text: 'Análise Solo',
        icon: <TimelineIcon />,
        component: <SoilAnalysisListPage />
    },

    {
        path: '/report/recommendation',
        text: 'Recomendação',
        icon: <PausePresentationIcon />,
        component: <RecommendationPage />
    },
    {
        path: '/list/recommendation',
        text: 'Recomendações',
        icon: <BallotIcon />,
        component: <RecommendationListPage />
    },
]
