import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent, {OptionsColumn} from "../../../components/table";
import ApiService from "../../../service";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {useHistory} from "react-router-dom";
import RecommendationPage from "../index";

export default function RecommendationReportPage (){
    const [data, setData] = useState([]);
    let history = useHistory();
    const columns = [
        {
            name: "soil_analysis",
            label: "Grid / Talhão / Propriedade",
            options: {customBodyRender: (value)=> value?.grid?.identifier + ' - ' + value?.grid?.plot_name}
        },
        {
            name: "date",
            label: "Data gerado",
            options: {customBodyRender: (value)=> value?.slice(0,10)}
        },
        {
            name: "npk",
            label: "NPK",
            options: {customBodyRender: (value)=>(<span>{value?.npk?.formule?.join('-')}</span>)}
        },
        {
            name: "iqs",
            label: "IQS",
            options: {customBodyRender: (value)=>(<span>{value?.iqs?.value}</span>)}
        },
        {
            name: "soil_class",
            label: "Classe de solo",
            options: {customBodyRender: (value)=>(<span>{value?.texture_class}</span>)}
        }
    ];

    const getData = useCallback(async () => {
        let response = await ApiService.get('recommendation/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);

    const customAction = {
        "action": async (listIds, values) => {
            for (let index in listIds) {
                let itemId = listIds[index];
                history.push(history.location.pathname.replace('list', 'report')+`?id=${itemId}`);
            }
        },
        "icon": (<VisibilityIcon/>),
        "label": "Visualizar Relatório"
    }

    return <TableComponent
        columns={columns}
        data={data}
        title={'Recomendações'}
        enableEditAction={false}
        enableCreateAction={false}
        customAction={customAction}
    />
}
