import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent, {OptionsColumn} from "../../../components/table";
import ApiService from "../../../service";
import {
    AreaCondition,
    CurrentCulture,
    FirstSecondPlanting,
    ManagementSystem,
    PredecessorCulture
} from "../form/options";

export default function PlotListPage (){
    const [data, setData] = useState([]);
    const columns = [
        {
            name: "identifier",
            label: "Identificador",
        },
        {
            name: "farm",
            label: "Propriedade",
            options: {customBodyRender: (value)=>(<span>{value?.name}</span>)}
        },
        {
            name: "area_hectare",
            label: "Área(ha)",
        },
        {
            name: "plant_density_hectare",
            label: "Densidade de Plantas(ha)",
        },
        {
            name: "current_culture",
            label: "Cultura",
            options: {customBodyRender: (value)=>OptionsColumn(value, CurrentCulture)},
        },
        {
            name: "first_or_second_planting",
            label: "Primeiro Segundo plantio",
            options: {customBodyRender: (value)=>OptionsColumn(value, FirstSecondPlanting)},
        },
        {
            name: "prnt_applied",
            label: "PRNT(%)",
        },
        {
            name: "predecessor_culture",
            label: "Cultura antecedente",
            options: {customBodyRender: (value)=>OptionsColumn(value, PredecessorCulture)},
        },
        {
            name: "yield_expectation_kg",
            label: "Expectativa de rendimento(kg)",
        },
        {
            name: "area_condition",
            label: "Condição da área",
            options: {customBodyRender: (value)=>OptionsColumn(value, AreaCondition)},
        },
        {
            name: "management_system",
            label: "Sistema de manejo",
            options: {customBodyRender: (value)=>OptionsColumn(value, ManagementSystem)},
        }
    ];

    const getData = useCallback(async () => {
        let response = await ApiService.get('plot/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);


    return <TableComponent
        columns={columns}
        data={data}
        title={'Talhões'}
    />
}