export const FirstSecondPlanting = [
    {title: 'Primeiro', value: 1},
    {title: 'Segundo', value: 2}
];

export const PredecessorCulture = [
    {title: 'Gramínea', value: 'grass'},
    {title: 'Leguminosa', value: 'legume'}
];

export const CurrentCulture = [
    {title: 'Milho', value: 'corn'},
];

export const ManagementSystem = [
    {title: 'Convencional', value: 'conventional'},
    {title: 'Plantio direto', value: 'no-till'},
];

export const AreaCondition = [
    {title: 'Em todos os casos', value: 'in_all_cases'},
    {title: 'Implantação do sistema', value: 'system_implementation'},
    {title: 'Consolidado sem restrições', value: 'Consolidated_system_no_restriction'},
    {title: 'Consolidado com restrições', value: 'Consolidated_system_with_restriction'},
];