import React from 'react';
import {
    Button,
    ButtonGroup
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

export const FORM_BUTTON_TYPES = {
    SUBMIT: 'submit',
    CLEAR: 'clear',
    CANCEL: 'cancel',
    CUSTOM: 'custom'
}

export const DEFAULT_BUTTON_SET = [
    {
        name: 'cancel',
        label: 'Cancelar',
        type: FORM_BUTTON_TYPES.CANCEL,
    },
    {
        name: 'clear',
        label: 'Limpar',
        type: FORM_BUTTON_TYPES.CLEAR
    },
    {
        name: 'submit',
        label: 'Enviar',
        type: FORM_BUTTON_TYPES.SUBMIT,
    }
]

/**
 * Receive a button json object and generate the button component.
 * 
 * Ex: {name, label, action, color, type}
 * 
 * @param json field 
 * @returns Renderable field component.
 */
export default function FormButtonComponent({button}) {
    let history = useHistory();
    
    const cancelForm = (event) => {
        event.preventDefault();
        history.goBack();
    }

    const renderButtons = () => {

        if(button === undefined || button.length < 1) return (<Alert severity="info">Não há botões configurados para esse formulário.</Alert>);

        switch (button.type) {
            case FORM_BUTTON_TYPES.SUBMIT: return renderSubmitButton();
            case FORM_BUTTON_TYPES.CLEAR: return renderClearButton();
            case FORM_BUTTON_TYPES.CANCEL: return renderCancelButton();
            case FORM_BUTTON_TYPES.CUSTOM: return renderCustomButton();
            default: return (<Alert severity="error">Erro ao renderizar botão do formulário!</Alert>);
        }
    }

    const renderSubmitButton = () => {
        return (
            <Box ml={2}>
                <Button
                    id={button.name}
                    variant="contained"
                    color="primary"
                    type="submit">
                    {button.label}
                </Button>
            </Box>

        );
    }

    const renderClearButton = () => {
        return (
            <Box ml={2}>
                <Button
                    id={button.name}
                    variant="contained"
                    type="reset">
                    {button.label}
                </Button>
            </Box>

        );
    }

    const renderCancelButton = () => {        
        return (
            <Box ml={2}>
                <Button
                    id={button.name}
                    variant="contained"
                    color="secondary"
                    onClick={cancelForm}>
                    {button.label}
                </Button>
            </Box>

        );
    }

    const renderCustomButton = () => {        
        return (
            <Button
                id={button.name}
                variant="contained"
                color={button.color}
                onClick={button.action}>
                {button.label}
            </Button>
        );
    }    

    return (<ButtonGroup>{renderButtons()}</ButtonGroup>);
}