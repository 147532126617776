import * as React from 'react';
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import {DEFAULT_BUTTON_SET} from "../../../components/form-builder/buttons";
import FormBuilderComponent from "../../../components/form-builder";
import validators from "../../../components/form-builder/validators";

export default function FarmFormPage() {
    const formMock = {
        id: 'form',
        name: 'form',
        action: 'save-home',
        fields: [
            {
                id: 'name',
                name: 'name',
                label: 'Nome',
                description: 'Nome da propriedade',
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                id: 'owner',
                name: 'owner',
                label: 'Proprietário',
                description: 'Nome do proprietário',
                validators: {
                    required: validators.required(),
                },
                type: FORM_FIELD_TYPES.TEXT
            },
            {
                id: 'city',
                name: 'city',
                label: 'Cidade',
                description: 'Cidade da propriedade',
                type: FORM_FIELD_TYPES.TEXT,
                // defaultValue: 'Chapecó'
            },
        ],
        buttons: DEFAULT_BUTTON_SET
    }

    return <FormBuilderComponent form={formMock} title={'Cadastro de propriedade'}/>
}
