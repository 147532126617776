export const nitrogenOptions = [
    {title: 'Uréia', value: 'urea'},
    {title: 'Sulfato de amônio', value: 'ammonium_sulfate'},
    {title: 'Nitrato de amônio', value: 'ammonium_nitrate'},
    {title: 'Nitrato de cálcio', value: 'calcium_nitrate'},
]


export const phosphateOptions = [
    {title: 'Superfosfato simples', value: 'single_superphosphate'},
    {title: 'Superfosfato triplo', value: 'triple_superphosphate'},
    {title: 'Fosfato monoamônico (MAP)', value: 'monoammonium_phosphate_map'},
    {title: 'Fosfato diaamônico (DAP)', value: 'diammonium_phosphate_dap'},
    {title: 'Fosfato natural parcialmente acidulado', value: 'partially_acidulated_rock_phosphate'},
    {title: 'Termofosfato magnesiano', value: 'magnesium_thermophosphate'},
    {title: 'Fosfato natural', value: 'rock_phosphate'},
    {title: 'Fosfato natural reativo', value: 'reactive_rock_phosphate'},
    {title: 'Farinha de ossos calcinada', value: 'calcined_bone_meal'},
]

export const potashOptions = [
    {title: 'Cloreto de potássio', value: 'potassium_chloride'},
    {title: 'Sulfato de potássio', value: 'potassium_sulfate'}
]

export const organicFertilizerOptions = [
    {title: 'Cama de frango (3 e 4 lotes)', value: 'chicken_bed_3_4_lots'},
    {title: 'Cama de frango (5 e 6 lotes)', value: 'chicken_bed_5_6_lots'},
    {title: 'Cama de frango (7 e 8 lotes)', value: 'chicken_bed_7_8_lots'},
    {title: 'Cama de peru (2 lotes)', value: 'turkey_bed_2_lots'},
    {title: 'Cama de poedeira', value: 'laying_bed'},
    {title: 'Cama sobreposta de suínos', value: 'bunk_pig_bed'},
    {title: 'Composto de dejeto de suínos', value: 'swine_manure_compound'},
    {title: 'Esterco sólido de suínos', value: 'solid_swine_manure'},
    {title: 'Esterco sólido de bovinos', value: 'solid_cattle_manure'},
    {title: 'Vermicomposto', value: 'vermicompost'},
    {title: 'Lodo de esgoto', value: 'sewage_sludge'},
    {title: 'Composto de lixo urbano', value: 'compost_of_urban_waste'},
    {title: 'Cinza de casca de arroz', value: 'rice_husk_ash'},
    {title: 'Dejeto líquido de suínos', value: 'pig_liquid_waste'},
    {title: 'Dejeto líquido de bovinos', value: 'bovine_liquid_waste'}
]
