import * as React from 'react';
import { FORM_FIELD_TYPES } from "../../../components/form-builder/fields";
import { DEFAULT_BUTTON_SET } from "../../../components/form-builder/buttons";
import FormBuilderComponent from "../../../components/form-builder";
import { PlantingType, Seeds } from "./options";
import validators from "../../../components/form-builder/validators";

export default function AgriculturalYearFormPage() {
    const formMock = {
        id: 'AgriculturalYear',
        name: 'AgriculturalYear',
        action: 'save-home',
        fields: [
            // {
            //     name: 'plot',
            //     label: 'Talhão',
            //     type: FORM_FIELD_TYPES.AUTOCOMPLETE,
            //     path: 'plot/search-by-name',
            //     optionKey: 'identifier',
            //     validators: {
            //         required: validators.required(),
            //     }
            // },
            {
                name: 'planting_type',
                label: 'Tipo ano agrícola',
                type: FORM_FIELD_TYPES.SELECT,
                options: PlantingType,
            },
            {
                name: 'seed',
                label: 'Semente',
                type: FORM_FIELD_TYPES.SELECT,
                options: Seeds,
            },
            {
                name: 'harvested_bags',
                label: 'Sacas colhidas',
                type: FORM_FIELD_TYPES.NUMBER,
                defaultValue: 0
            },
            {
                name: 'planting_date',
                label: 'Data plantio',
                type: FORM_FIELD_TYPES.DATE,
                defaultValue: null
            },
            {
                name: 'hasvest_date',
                label: 'Data colheita',
                type: FORM_FIELD_TYPES.DATE,
                defaultValue: null
            },
        ],
        buttons: DEFAULT_BUTTON_SET
    }

    return <FormBuilderComponent form={formMock} title={'Cadastro de Ano Agrícola'} />
}
