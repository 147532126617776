import * as React from 'react';
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";
import {DEFAULT_BUTTON_SET} from "../../../components/form-builder/buttons";
import FormBuilderComponent, {useQuery} from "../../../components/form-builder";
import {useCallback, useEffect, useState} from "react";
import ApiService from "../../../service";
import {format} from "date-fns";

export default function SoilAnalysisFormPage() {
    const [gridOptions, setGridOptions] = useState([]);
    const [chemicalOptions, setChemicalOptions] = useState([]);
    const [biologicalOptions, setBiologicalOptions] = useState([]);
    const [physicalOptions, setPhysicalOptions] = useState([]);
    const objectId = useQuery().get('id');

    const onChangeLabReports = useCallback(async (watch, setValue) => {
        const dataPayload = {id: watch('grid').id};
        const responseChemical = await ApiService.get('chemical-lab-report/search-by-grid', dataPayload);
        const responseBiological = await ApiService.get('biological-lab-report/search-by-grid', dataPayload);
        const responsePhysical = await ApiService.get('physical-lab-report/search-by-grid', dataPayload);
        if (responseChemical?.status === 200) setChemicalOptions(responseChemical.data);
        if (responseBiological?.status === 200) setBiologicalOptions(responseBiological.data);
        if (responsePhysical?.status === 200) setPhysicalOptions(responsePhysical.data);
    }, [])

    const onChangeFarm = useCallback(async (watch, setValue) => {
        const response = await ApiService.get('grid/search-by-farm',{id: watch('farm').id})
        if (response?.status !== 200) return;
        setGridOptions(response.data);
    }, [])

    const onChange = (watch, setValue)=>{
        if(objectId) return

        if(!!watch('farm'))
            onChangeFarm(watch, setValue);

        if(!!watch('grid'))
            onChangeLabReports(watch, setValue);
    }

    const formMock = {
        id: 'form',
        name: 'form',
        action: 'save-home',
        fields: [
            {
                name: 'farm',
                label: 'Propriedade',
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'farm/search-by-name',
                optionKey: 'name',
                onChange
            },
            {
                name: "grid",
                label: "Grid",
                type: FORM_FIELD_TYPES.SELECT,
                options: gridOptions,
                optionTitle: 'name',
                // validators: {
                //     required: validators.required(),
                // },
            },
            {
                name: "physical_lab_report",
                label: "Análise Física",
                type: FORM_FIELD_TYPES.SELECT,
                options: physicalOptions,
                optionTitle: 'name',
                // validators: {
                //     required: validators.required(),
                // },
            },
            {
                name: "biological_lab_report",
                label: "Análise Biológica",
                type: FORM_FIELD_TYPES.SELECT,
                options: biologicalOptions,
                optionTitle: 'name',
                // validators: {
                //     required: validators.required(),
                // },
            },
            {
                name: "chemical_lab_report",
                label: "Análise Química",
                type: FORM_FIELD_TYPES.SELECT,
                options: chemicalOptions,
                optionTitle: 'name',
                // validators: {
                //     required: validators.required(),
                // },
            },
            {
                name: "agricultural_year",
                label: "Ano Agrícola",
                type: FORM_FIELD_TYPES.AUTOCOMPLETE,
                path: 'agricultural-year/search-by-user',
                optionKey: 'name'
            },
            {
                name: "register_date",
                label: "Data de registro",
                type: FORM_FIELD_TYPES.DATE,
                defaultValue: format(new Date(), 'yyyy/MM/dd')
                // validators: {
                //     required: validators.required(),
                // }
            }
        ],
        buttons: DEFAULT_BUTTON_SET
    }

    return <FormBuilderComponent form={formMock} title={'Cadastro de Análise de solo'}/>
}
