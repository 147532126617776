import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Chip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    "MUITO-BAIXO": {width: '30%', background: '#ff0023'},
    "BAIXO": {width: '30%', background: '#ff6e3b'},
    "MÉDIO": {width: '30%', background: '#fffc74'},
    "ALTO": {width: '30%', background: '#9afd71'},
    "MUITO-ALTO": {width: '30%', background: '#33ae67'},
}));

export default function SoilClassBar({indexClass, chipLabel}) {
    const classes = useStyles();
    indexClass = indexClass?.replace(' ', '-');

    return (
        <Box width={1}>
            <Chip
                size={'medium'}
                className={classes[indexClass]}
                label={chipLabel}
            />
        </Box>
    );
}
