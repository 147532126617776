import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import TableComponent from "../../../components/table";
import ApiService from "../../../service";
import {FORM_FIELD_TYPES} from "../../../components/form-builder/fields";

export default function ChemicalAnalysisListPage (){
    const [data, setData] = useState([]);
    const columns = [
        {name: 'grid', label: 'Grid / Talhão / Propriedade',
            options: {customBodyRender: (value)=> value?.identifier + ' - ' + value?.plot_name}},
        {name: 'name_lab', label: 'Nome do laboratório'},
        {name: 'report_number_lab', label: "número de relatório do laboratório"},
        {name: 'ph_water', label: 'Ph em água'},
        {name: 'smp', label: 'Índice SMP' },
        {name: 'relation_ca_mg', label: 'Relação Ca/Mg' },
        {name: 'clay', label: 'Argila' },
        {name: 'organic_matter', label: 'Matéria orgânica' },
        {name: 'p_mg_l', label: 'P mg/l' },
        {name: 'k_mg_l', label: 'K mg/l' },
        {name: 'ctc_dm_3', label: 'CTC' },
    ];

    const getData = useCallback(async () => {
        let response = await ApiService.get('chemical-lab-report/')
        if (response?.status === 200)
            setData(response?.data)
    }, [])

    useEffect(()=>{
        getData();
    }, [getData]);


    return <TableComponent
        columns={columns}
        data={data}
        title={'Análises Química'}
    />
}
