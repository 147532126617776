// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from "@material-ui/core/styles";
import ApiService from "../../service";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    w100: {
        width: '100%'
    },
}));


export default function AutoCompleteField(
{
    defaultValue,
    optionKey,
    watch,
    label,
    name,
    path,
    setValue,
    validators={},
    control,
    errors, takeValues, setTakeValues
}
) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValueAutoComplete] = useState(watch(name));
    const [inputValue, setInputValue] = useState();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const updateAutoComplete = async () => {

        if (!inputValue || inputValue?.length < 1) return;

        setOpen(true);
        setLoading(true);
        const result = await ApiService.get(`${path}`, {searchTerm: inputValue});
        setLoading(false);
        if (result?.status !== 200) return;
        setOptions(result.data);
    }

    const onClose = () => {
        setOpen(false);
        if (value){
            setValue(name, value);
        }
    }

    if (value && value !== value)
        setValue(name, value);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={validators}
            render={({ field }) => <Autocomplete
                value={value}
                defaultValue={defaultValue}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    setValue(name, newValue);
                    setValueAutoComplete(newValue);
                    setTakeValues({...takeValues, [name]: newValue})
                }}
                className={classes.w100}
                open={open}
                onOpen={updateAutoComplete}
                onClose={()=>onClose()}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option[optionKey]}
                options={options}
                loading={loading}
                renderInput={(params) => {
                    // console.log('params', params)
                    return (
                        <TextField
                            {...params}
                            label={label}
                            variant="outlined"
                            error={!!errors[name]}
                            helperText={errors[name]?.message}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )
                }}
            />}
        />

    );
}
